import { Icon, MenuItem } from "@material-ui/core";
import React from "react";

import { PresentationFilters } from "common/constants";
import { FeatureType } from "common/features";
import PresentationLibraryController, { PresentationLibraryFilter, PresentationLibraryPresentation } from "js/controllers/PresentationLibraryController";
import AppController from "js/core/AppController";
import { PresentationTemplateController } from "js/core/dataServices/PresentationTemplateDataService";
import { TeamFoldersController } from "js/core/dataServices/TeamFoldersDataService";
import { ds } from "js/core/models/dataService";
import { getPresentation } from "js/core/models/presentation";
import withProgressDialog from "js/core/utilities/withProgressDialog";
import { UIController } from "js/editor/dialogs/UIController";
import { app } from "js/namespaces";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowErrorDialog,
} from "js/react/components/Dialogs/BaseDialog";
import InputConfirmationDialog from "js/react/components/Dialogs/InputConfirmationDialog";
import MoveWorkspacesDialog from "js/react/components/Dialogs/MoveWorkspacesDialog";
import { ShareDialog } from "js/react/views/PresentationSettings/dialogs/ShareDialog";
import ShareViewOnlyPresentationDialog from "js/react/views/PresentationSettings/dialogs/ShareViewOnlyPresentationDialog";
import CreateTeamTemplateDialog from "js/react/views/TeamResources/dialogs/CreateTeamTemplateDialog";

export function getPresentationContextMenuItems({ selectedPresentations, currentFilter }: { selectedPresentations: PresentationLibraryPresentation[], currentFilter: PresentationLibraryFilter }) {
    const getFirstPresentationModel = () => {
        return getPresentation(selectedPresentations[0].id, { permission: "write", autoSync: true });
    };

    const getPresentationModels = (permission: "read" | "write" = "write") => {
        return Promise.all(selectedPresentations.map(p => getPresentation(p.id, { permission, autoSync: true })));
    };

    const handleEdit = event => {
        const openInNewTab = event.metaKey || event.shiftKey;
        AppController.showEditor({ presentationId: selectedPresentations[0].id, openInNewTab });
    };

    const handlePresent = () => {
        PresentationLibraryController.playPresentation(selectedPresentations[0].id);
    };

    const handleRename = () => {
        ShowDialog(InputConfirmationDialog, {
            title: "Rename presentation",
            message: null,
            input: {
                value: selectedPresentations[0].name
            },
            acceptCallback: value => {
                withProgressDialog("Renaming presentation...", null, async () => {
                    try {
                        const presentation = await getFirstPresentationModel();
                        await presentation.rename(value);
                        await PresentationLibraryController.forceRefreshPresentations([presentation.id]);
                        presentation.disconnect();
                    } catch (err) {
                        ShowErrorDialog({
                            title: "Unable to rename presentation",
                            message: err.message
                        });
                    }
                });
            },
        });
    };

    const handleDuplicate = () => {
        withProgressDialog("Duplicating presentation...", null, async () => {
            try {
                const duplicatedPresentation = await PresentationLibraryController.duplicatePresentation({ presentationId: selectedPresentations[0].id, libraryFilter: currentFilter });
                AppController.showEditor({ presentationId: duplicatedPresentation.id });
            } catch (err) {
                ShowErrorDialog({ title: "Unable to duplicate presentation(s)", message: err.message });
            }
        });
    };

    const handleDelete = async () => {
        const ownedPresentations = selectedPresentations.filter(p => p.permissions.owner);
        const collaboratedPresentations = selectedPresentations.filter(p => !p.permissions.owner);

        if (ownedPresentations.length > 0) {
            withProgressDialog("Moving presentations to trash...", null, () =>
                PresentationLibraryController.trashPresentations(ownedPresentations.map(p => p.id))
                    .catch(err => {
                        ShowErrorDialog({
                            title: "Unable to delete presentation(s)",
                            message: err.message
                        });
                    })
            );
        }

        if (collaboratedPresentations.length > 0) {
            ShowConfirmationDialog({
                title: "Are you sure you want to stop collaborating on the selected presentation(s)?",
                message: "Shared presentations will be unshared with you and you will no longer be able to view or edit them unless they are reshared with you.",
                acceptCallback: () => {
                    PresentationLibraryController.deletePresentations(collaboratedPresentations.map(p => p.id))
                        .catch(err => {
                            ShowErrorDialog({
                                title: "Unable to delete presentation(s)",
                                message: err.message
                            });
                        });
                }
            });
        }
    };

    const handleDeletePermanently = () => {
        ShowConfirmationDialog({
            title: "Are you sure you want to permanently delete this presentation?",
            message: "You can't undo this action.",
            acceptCallback: () => {
                PresentationLibraryController.deletePresentations(selectedPresentations.map(p => p.id))
                    .catch(err => {
                        ShowErrorDialog({
                            title: "Unable to delete presentation(s)",
                            message: err.message
                        });
                    });
            }
        });
    };

    const handleMoveWorkspaces = () => {
        ShowDialog(MoveWorkspacesDialog, {
            // Legacy-compatible format
            presentations: selectedPresentations,
            currentWorkspaceId: UIController.getWorkspaceId(),
            workspaces: Object.values(app.user.workspaces),
            onClose: () => {
                PresentationLibraryController.forceRefreshPresentations(selectedPresentations.map(p => p.id));
            }
        });
    };

    const handleRecoverFromTrash = () => {
        PresentationLibraryController.recoverPresentations(selectedPresentations.map(p => p.id))
            .catch(err => {
                ShowErrorDialog({
                    title: "Unable to recover presentation(s)",
                    message: err.message
                });
            });
    };

    const handleShareViewOnlyPresentation = () => {
        getPresentationModels("read")
            .then(presentations => {
                ShowDialog(ShareViewOnlyPresentationDialog, { presentation: presentations[0], onClose: () => presentations.forEach(p => p.disconnect()) });
            })
            .catch(err => {
                ShowErrorDialog({
                    title: "Unable to share presentation(s)",
                    message: err.message
                });
            });
    };

    const handleSendPresentation = () => {
        getPresentationModels()
            .then(presentations => {
                ShowDialog(ShareDialog, { presentation: presentations[0], onClose: () => presentations.forEach(p => p.disconnect()) });
            })
            .catch(err => {
                ShowErrorDialog({
                    title: "Unable to share presentation(s)",
                    message: err.message
                });
            });
    };

    const handleSharePresentation = () => {
        getPresentationModels()
            .then(presentations => {
                ShowDialog(ShareDialog, { presentation: presentations[0], selectedPanel: "collaborate", onClose: () => presentations.forEach(p => p.disconnect()) });
            })
            .catch(err => {
                ShowErrorDialog({
                    title: "Unable to share presentation(s)",
                    message: err.message
                });
            });
    };

    const handleRemoveFromFolder = () => {
        withProgressDialog(`Removing presentation from folder...`, null, async () => {
            try {
                for (const presentation of selectedPresentations) {
                    const folder = ds.userFolders.get(currentFilter.folderId);
                    folder.removePresentationFromFolder(presentation.id);
                    await folder.updatePromise;
                }
            } catch (err) {
                ShowErrorDialog({
                    title: "Unable to remove presentation(s)",
                    message: err.message
                });
            }
        });
    };

    const handleRemoveFromTeamFolder = () => {
        const teamFolder = ds.teams.get(currentFilter.folderId);
        withProgressDialog(`Removing presentation from ${teamFolder.get("name")}...`, null, async () => {
            try {
                const presentation = await getFirstPresentationModel();
                await TeamFoldersController.removePresentationFromTeamFolder(presentation, currentFilter.folderId, currentFilter.subFolderId);
                presentation.disconnect();
            } catch (err) {
                ShowErrorDialog({
                    title: "Unable to remove presentation(s)",
                    message: err.message
                });
            }
        });
    };

    const handleConvertToTemplate = async () => {
        const existingTags = [];
        const presentation = await getFirstPresentationModel();
        const name = presentation.get("name") || "";
        const description = presentation.get("description") || "";
        const tags = presentation.get("tags") || [];
        ds.selection.element = null;
        ShowDialog(CreateTeamTemplateDialog, {
            title: "Create a template from this presentation?",
            subTitle: "Publish a copy of this presentation for your team to start from—or save it as a draft and publish it later. View and edit this template in Team Resources at any time.",
            name,
            description,
            tags,
            existingTags,
            callback: async ({
                isPublished,
                name,
                description,
                tags,
            }) => {
                await PresentationTemplateController.createPresentationTemplate(
                    presentation,
                    {
                        isTemplateConfirmed: true,
                        isPublished,
                        name,
                        description,
                        tags,
                    },
                );
                presentation.disconnect();
            }
        });
    };

    const MenuItems = [];

    const renderMoveToAnotherWorkspace = (hasOwned, includeDivider = false) => {
        if (hasOwned && Object.values(app.user.workspaces).length > 1) {
            let prohibitExternalWorkspaceMovement = app.user.features.isFeatureEnabled(
                FeatureType.PROHIBIT_EXTERNAL_WORKSPACE_MOVEMENT,
                UIController.getWorkspaceId()
            );
            if (!prohibitExternalWorkspaceMovement) {
                MenuItems.push(
                    <MenuItem
                        key="move_workspace"
                        divider={includeDivider}
                        onClick={handleMoveWorkspaces}
                    >
                        <Icon>business</Icon>Move To Another Workspace
                    </MenuItem>);
            }
        }
    };

    if (selectedPresentations.length == 1) {
        const presentation = selectedPresentations[0];

        if (currentFilter.type == PresentationFilters.TRASH) {
            MenuItems.push(
                <MenuItem key="recover" onClick={handleRecoverFromTrash}>
                    <Icon>restore_from_trash</Icon>
                    Recover From Trash
                </MenuItem>
            );
            MenuItems.push(
                <MenuItem key="delete-permanently" onClick={handleDeletePermanently}>
                    <Icon>delete_forever</Icon>Delete Permanently
                </MenuItem>
            );
        } else {
            MenuItems.push(
                <MenuItem key="present-all" divider onClick={handlePresent}>
                    <Icon>present_to_all</Icon>Present
                </MenuItem>
            );

            if (presentation.permissions.owner || presentation.permissions.write) {
                MenuItems.push(
                    <MenuItem key="edit" onClick={handleEdit}>
                        <Icon>edit</Icon>Edit
                    </MenuItem>
                );
                MenuItems.push(<hr key="divider2" />);

                MenuItems.push(
                    <MenuItem key="send" onClick={handleSendPresentation}>
                        <Icon>share</Icon>Share...
                    </MenuItem>
                );
                MenuItems.push(
                    <MenuItem key="share" divider onClick={handleSharePresentation}>
                        <Icon>person_add_alt_1</Icon>Invite Collaborators...
                    </MenuItem>
                );

                {
                    app.user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, UIController.getWorkspaceId()) &&
                        MenuItems.push(
                            <MenuItem key="convert-to-template" divider onClick={handleConvertToTemplate}>
                                <Icon>file_copy</Icon>Create Team Template...
                            </MenuItem>
                        );
                }
                MenuItems.push(
                    <MenuItem key="rename" onClick={handleRename}>
                        <Icon>label</Icon>Rename
                    </MenuItem>
                );
            } else {
                MenuItems.push(
                    <MenuItem divider key="send" onClick={handleShareViewOnlyPresentation}>
                        <Icon>share</Icon>Share...
                    </MenuItem>
                );
            }

            MenuItems.push(
                <MenuItem key="duplicate" onClick={handleDuplicate}>
                    <Icon>content_copy</Icon>Duplicate
                </MenuItem>
            );

            renderMoveToAnotherWorkspace(presentation.permissions.owner, false);

            MenuItems.push(<hr key="divider4" />);

            if (currentFilter.type == PresentationFilters.FOLDER) {
                MenuItems.push(
                    <MenuItem key="remove" divider onClick={handleRemoveFromFolder}>
                        <Icon>remove_circle</Icon>Remove from Folder
                    </MenuItem>
                );
            }
            if (
                currentFilter.type == PresentationFilters.TEAM_FOLDER &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_MODIFY_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId) &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId)
            ) {
                MenuItems.push(
                    <MenuItem key="remove" divider onClick={handleRemoveFromTeamFolder}>
                        <Icon>remove_circle</Icon>Remove from Team Folder
                    </MenuItem>
                );
            }

            if (presentation.permissions.owner) {
                MenuItems.push(
                    <MenuItem key="trash" onClick={handleDelete}>
                        <Icon>delete</Icon>Move To Trash
                    </MenuItem>
                );
            } else {
                MenuItems.push(
                    <MenuItem key="unshare" onClick={handleDelete}>
                        <Icon>delete</Icon>Unshare with me
                    </MenuItem>
                );
            }
        }
    } else {
        if (currentFilter.type == PresentationFilters.TRASH) {
            MenuItems.push(
                <MenuItem key="recover"
                    onClick={handleRecoverFromTrash}>
                    <Icon>restore_from_trash</Icon>
                    Recover From Trash
                </MenuItem>
            );
            MenuItems.push(
                <MenuItem key="delete-permanently"
                    onClick={handleDeletePermanently}>
                    <Icon>delete_forever</Icon>
                    Delete Permanently
                </MenuItem>
            );
        } else {
            if (
                currentFilter.type == PresentationFilters.FOLDER &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_MODIFY_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId) &&
                app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_ALL_TEAM_MEMBERS_FOLDER, AppController.workspaceId)
            ) {
                MenuItems.push(
                    <MenuItem key="remove"
                        onClick={handleRemoveFromFolder}>
                        <Icon>remove_circle</Icon>
                        Remove from Folder
                    </MenuItem>
                );
            }

            let hasShared = false;
            let hasOwned = false;
            for (let presentation of selectedPresentations) {
                if (presentation.permissions.owner) {
                    hasOwned = true;
                } else {
                    hasShared = true;
                }
            }

            renderMoveToAnotherWorkspace(hasOwned, true);

            let label;
            if (hasOwned && hasShared) {
                label = "Move to Trash or Unshare";
            } else if (hasOwned) {
                label = "Move to Trash";
            } else {
                label = "Unshare with me";
            }

            MenuItems.push(
                <MenuItem key="trash"
                    onClick={handleDelete}>
                    <Icon>delete</Icon>
                    {label}
                </MenuItem>
            );
        }
    }

    return MenuItems;
}
