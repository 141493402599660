import React, { useEffect, useRef } from "react";
import Icon from "js/react/components/Icon";
import { themeColors } from "js/react/sharedStyles";
import { Gap5 } from "./Gap";

const highlightText = (text, highlight) => {
    // Escape special characters for regex
    let regexHighlight = highlight.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
    // Split text on highlight term; include term itself into parts; ignore case
    let parts = text.split(new RegExp(`(${regexHighlight})`, "gi"));
    let result = (<>{
        parts.map((part, index) => {
            let className = part.toLowerCase() === highlight.toLowerCase() ? "highlighted" : "underlighted";
            return <span key={index} className={className}>{part}</span>;
        })
    }</>);
    return result;
};

export default function Avatar({ url, onClick = () => {}, id = undefined }) {
    return (
        <div id={id} className="avatar user" onClick={onClick}>
            {url ? <img src={url} /> : <Icon iconName="person" />}
        </div>
    );
}

export function FolderAvatar({ url }) {
    return (
        <div className="avatar folder" style={{ background: "none", color: themeColors.ui_blue }}>
            {
                url
                    ? <img src={url} />
                    : <Icon
                        style={{
                            fontSize: "34px",
                            lineHeight: "34px",
                            marginTop: "-2px",
                            width: "30px",
                            height: "30px",
                        }}
                        iconName="folder_shared"
                    />
            }
        </div>
    );
}

export function TeamAvatar({ url }) {
    return (
        <div className="avatar team" style={{ background: "none", color: themeColors.ui_blue }}>
            {url ? <img src={url} /> : <Icon iconName="business" />}
        </div>
    );
}

export function UserProfile({ profile, highlight }) {
    const profileRef = useRef(null);

    const handleClickOutside = event => {
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            window.getSelection().removeAllRanges();
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    let {
        photoURL,
        displayName,
        email,
        isInTeam,
        onClick,
        isPending,
    } = profile;

    if (highlight) {
        if (displayName) {
            displayName = highlightText(displayName, highlight);
        }
        if (email) {
            email = highlightText(email, highlight);
        }
    }

    return (
        <div ref={profileRef} className="profile" onClick={onClick}>
            <Avatar url={photoURL} />
            <div className={displayName ? "name-email" : "email-only"}>
                {
                    displayName
                        ? (<><div className="name">{displayName}</div><div className="email">{email}</div></>)
                        : <div className="email">{email}</div>
                }
                {
                    isPending &&
                    <div className="pending">Pending sign-up</div>
                }
            </div>
            {
                // NOTE: Removed until recent collaborators is implemented.

                // isInTeam &&
                // <>
                //     <Gap5 />
                //     <div className="team-badge">TEAM</div>
                // </>
            }
        </div>
    );
}

export function FolderProfile({ profile, highlight }) {
    let {
        photoURL,
        name,
        isDefault = false,
        onClick,
    } = profile;

    if (highlight) {
        if (name) {
            name = highlightText(name, highlight);
        }
    }

    return (
        <div className="profile" onClick={onClick}>
            {
                isDefault
                    ? <TeamAvatar url={photoURL} />
                    : <FolderAvatar url={photoURL} />
            }
            <div className="name-email">
                <div className="name">{name}</div>
            </div>
        </div>
    );
}
