import { isDevelopment, isPPTAddin, isStaging } from "js/config";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import { ds } from "js/core/models/dataService";
import { Slide } from "js/core/models/slide";
import { trackActivity } from "js/core/utilities/utilities";
import PresentationEditorController, { PanelType } from "js/editor/PresentationEditor/PresentationEditorController";
import { app } from "js/namespaces";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";

export class AddSlideController {
    static getSlideInsertIndex = () => {
        return PresentationEditorController.isInitialized
            ? PresentationEditorController.getCurrentSlideIndex() + 1
            : 0;
    }

    static addSlideFromTemplate = async (templateModel, replaceSlide, isTour) => {
        if (templateModel.isComingSoon && !isDevelopment && !isStaging) {
            ShowErrorDialog({
                error: "Sorry, this template isn't available yet",
                message: "We are working hard to get this template ready and it should be available soon."
            });
            return;
        }

        trackActivity("Template", "Selected", templateModel.id, null, { slide_type: templateModel.id });

        if (replaceSlide) {
            // if the current slide is a placeholder, replace the slide with a new slide created from the template
            const newSlideModel = await ds.selection.presentation.getTemplateSlideData(templateModel.id);
            this.updateSlidePlaceholder(replaceSlide, newSlideModel);
            return;
        }

        // otherwise, insert a new slide created from the template
        const insertIndex = this.getSlideInsertIndex();

        await ds.selection.presentation.addSlideFromTemplate(templateModel, insertIndex, { isTour, isSlideCreatedFromUserAction: true });
    }

    static addSlideFromModel = async slideModel => {
        // otherwise, insert a new slide created from the template
        const insertIndex = this.getSlideInsertIndex();
        const slides = await ds.selection.presentation.batchDuplicateSlides([slideModel], { insertIndex, isSlideCreatedFromUserAction: true });
        trackActivity("Template", "Selected", slideModel.template_id, null, { slide_type: slideModel.template_id });
        return slides;
    }

    static async updateSlidePlaceholder(replaceSlide, newSlideModel) {
        let slideText = "";
        if (replaceSlide.dataState.template_id === "slidePlaceholder") {
            if (replaceSlide.dataState.elements.primary.text) {
                slideText = replaceSlide.dataState.elements.primary.text?.text || replaceSlide.dataState.elements.primary.text?.blocks?.[0].html;
            }
        }
        const slideNotes = [slideText, replaceSlide.get("slide_notes")].filter(text => text).join("\n");
        if (slideNotes) {
            newSlideModel.slide_notes = slideNotes;
        }

        app.undoManager.openGroup();
        const slide = await ds.selection.presentation.replaceSlide(replaceSlide, newSlideModel);
        app.undoManager.closeGroup();

        if (PresentationEditorController.presentation) {
            await PresentationEditorController.setCurrentSlide(slide);
            await PresentationEditorController.togglePanel(PanelType.NOTES);
        }
    }

    static async copySlidesIntoPresentation(selectedSlides, replaceSlide, copySource) {
        const slides = await Promise.all(selectedSlides.map(slide => new Slide({ id: slide.id }, { autoSync: false }).load()));

        const copiedSlideCount = slides.length;
        const eventProps = {
            presentation_id: ds.selection.presentation.get("id"),
            //we only want to track slide ids for source_slides
            source_slides: selectedSlides.map(selectedSlide => ({ id: selectedSlide.id })),
            slide_template_names: slides.map(slide => slide.get("template_id")),
            current_slide_count: PresentationLibraryController.getSlideCount(ds.selection.presentation.getWorkspaceId()) + copiedSlideCount,
            slides_created: copiedSlideCount,
            workspace_id: ds.selection.presentation.getWorkspaceId(),
            copySource
        };
        trackActivity("Slide", "Copy", null, copiedSlideCount, eventProps, { audit: true });

        if (replaceSlide) {
            await this.updateSlidePlaceholder(replaceSlide, slides[0].attributes);
            if (slides.length === 1) {
                return;
            }
            slides.shift();
        }

        const insertIndex = this.getSlideInsertIndex();
        app.undoManager.openGroup();
        await ds.selection.presentation.batchDuplicateSlides(slides, { insertIndex, isSlideCreatedFromUserAction: true });
        app.undoManager.closeGroup();

        slides.forEach(slide => slide.disconnect());
    }

    static copySlideFromLibraryIntoPresentation = async slideId => {
        if (!isPPTAddin && slideId in ds.selection.presentation.get("slideRefs")) {
            throw new Error("This Team Slide has already been added to this presentation. It cannot be added more than once.");
        }

        const presentation = ds.selection.presentation;

        const newSlide = new Slide({ id: slideId }, {
            userId: app.user.id,
            presentation
        });
        await newSlide.load();

        const insertIndex = this.getSlideInsertIndex();
        app.undoManager.openGroup();
        await ds.selection.presentation.batchShareSlides([newSlide], { insertIndex, isSlideCreatedFromUserAction: true });
        app.undoManager.closeGroup();

        const props = {
            ...presentation.getAnalytics(),
            slide_id: slideId,
        };
        trackActivity("Presentation", "LibrarySlideAdded", null, null, props);
    }

    static addSlideFromTeamSlideTemplate = async (teamSlideTemplate, replaceSlide) => {
        const newSlide = new Slide({ id: teamSlideTemplate.id }, {
            userId: app.user.id,
            presentation: ds.selection.presentation
        });
        await newSlide.load();

        AddSlideController.addSlideFromModel(newSlide, { isSlideCreatedFromUserAction: true });

        trackActivity("TeamSlideTemplate", "Selected", teamSlideTemplate.id);
    }

    static replaceSlideInPresentation = async (slideId, currentSlide) => {
        const copySlide = await new Slide({ id: slideId }).load();
        ds.selection.presentation.replaceSlide(currentSlide, copySlide);
    }
}

