// Context for transports (is static for the whole session)
export interface LoggerTransportContext {
    browserId: string
    sessionId: string
    clientName: string
    clientVersion: string
    userAgent: string
}

// Context for log entries (is dynamic for each log entry)
export interface LogEntryContext {
    uid: string | undefined,
    location: string
}

export interface ApiTransportLogError {
    name: string,
    message: string,
    stack: string
}

export interface ApiTransportLogEntry {
    timestamp: number,
    level: LogLevel,
    group: LogGroup,
    context: LogEntryContext,
    message: string,
    data: LoggerTransportEntryData,
    error?: ApiTransportLogError
}

export interface ApiTransportLogEntryWithFullContext {
    timestamp: number,
    level: LogLevel,
    group: LogGroup,
    context: LogEntryContext & LoggerTransportContext,
    message: string,
    data: LoggerTransportEntryData,
    error?: ApiTransportLogError
}

export type LoggerTransportEntryData = Object | any[] | null;

export enum LogGroup {
    EDITOR = "editor",
    LIBRARY = "library",
    UNDO = "undo",
    CANVAS = "canvas",
    COLLABORATION = "collaboration",
    LAYOUT = "layout",
    ASSETS = "assets",
    THUMBNAIL = "thumbnail",
    LOGO = "logo",
    PLAYER = "player",
    PRESENTATION = "presentation",
    SLIDE = "slide",
    USER = "user",
    ADAPTER = "adapter",
    ANIMATION = "animation",
    SELECTION = "selection",
    TASK = "task",
    AUTH = "auth",
    AUTHORING = "authoring",
    MIGRATION = "migration",
    MONGO_MIGRATION = "mongoMigration",
    MANAGE_EMAILS = "manageEmails",
    SPELLCHECK = "spellcheck",
    NOTIFICATION = "notification",
    AUDIT = "audit",
    ANALYTICS = "analytics",
    EXPORT = "export",
    DATA_SERVICE = "dataService",
    CONVERT_TO_AUTHORING = "convertToAuthoring",
    OBSERVABLES = "observables",
    COMMENTS = "comments",
    PUSHER = "pusher",
    EXPORT_CACHE = "exportCache",
    DATA_SOURCE = "dataSource",
    DEFAULT = "default",
    WINDOW = "window",
    APP = "app",
    ELEMENTS = "elements",
    BILLING = "billing",
    INVITES = "invites",
    TEAMS = "teams",
    PERMISSIONS = "permissions",
    AUDIO = "audio",
    FONTS = "fonts",
    FEATURES = "features",
    STORAGE = "storage",
    PPT_IMPORT = "pptImport",
    THEMES = "themes",
    PPT_ADDIN = "pptAddin",
    ENTRYPOINT = "entrypoint",
    API = "api",
    RENDERER = "renderer",
    CANVAS_LAYOUTER = "canvasLayouter",
    CLIPBOARD = "clipboard",
    GDRIVE = "gDrive",
    LOCAL_STORAGE = "localStorage",
    HELP_MENU = "helpMenu",
    AI = "ai",
    LINKS = "links",
    SSO = "sso",
    SHARED_MODEL = "sharedModel",
    EXPERIMENTS = "experiments",
    DIALOGS = "dialogs",
    WORKSPACES = "workspaces",
    STRIPE = "stripe",
    CHECKOUT = "checkout",
    CONTROLLERS = "controllers"
}

export enum LogLevel {
    INFO = "INFO",
    WARNING = "WARNING",
    ERROR = "ERROR"
}
