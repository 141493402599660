import moment from "moment";
import { _ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { getValidChartDataFromCsv } from "js/core/utilities/xlsx";
import { AssetType, ConnectorType, ListStyleType, NodeType, TextStyleType } from "common/constants";
import { ShowErrorDialog, ShowConfirmationDialog } from "js/react/components/Dialogs/BaseDialog";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

const logger = getLogger(LogGroup.SHARED_MODEL);

interface TextContent {
    mainText: {
        text: string,
        textStyle?: typeof TextStyleType
    },
    secondaryTexts: {
        text: string,
        textStyle?: typeof TextStyleType
    }[],
    listStyle?: typeof ListStyleType
}

interface AssetContent {
    value: string,
    name: string,
    props: object,
    type: typeof AssetType,
    isMediaTextBlock?: boolean
}

export interface SharedModel {
    _rawElementModels: {
        [elementType: string]: Object
    }

    textContent: TextContent[],
    assets: AssetContent[],

    header: {
        text: TextContent, props: object
    },
    background: {
        asset: AssetContent, props: object
    },
    annotations: {},
    trayContent: {
        textContent: TextContent[],
        assets: AssetContent[]
        props: object,
    }
    listContent: {
        text: TextContent,
        asset: AssetContent,
        props: object,
    }[],
    compareContent: {
        value: number,
        text: TextContent,
        asset: AssetContent,
        emphasized: boolean,
    }[],
    tasks: {
        text: TextContent,
        asset: AssetContent,
        category: TextContent,
        startDate: number,
        endDate: number,
    }[],
    milestones: {
        label: TextContent,
        date: number
    }[],
    tabularData: {
        data: string[][],
        dataSourceLink: {},
        categories: string[],
        series: {
            name: string,
            values: string[]
        }[],
        annotations: {},
    }[],
    graphData: {
        nodes: {
            id: string,
            type: typeof NodeType,
            x: number,
            y: number,
            textContent: TextContent,
            asset: AssetContent,
            props: object,
        }[],
        connectors: {
            id: string,
            type: typeof ConnectorType,
            source: string,
            target: string,
            label: TextContent,
            props: object,
        }[]
    }[]
}

/**
 * Utility method that handles switching to different template or another variation of same template
 */
export async function switchSlideTemplate(canvas, templateId, templateProps = {}) {
    const originalModel = _.cloneDeep(canvas.model);
    const restoreOriginalModel = async () => {
        try {
            canvas.model = originalModel;
            canvas.updateTemplate(canvas.model.template_id);
            await canvas.refreshCanvas({ transition: false, forceRender: true });
            await canvas.saveCanvasModel();
        } catch (err) {
            logger.error(err, "switchTemplate() failed to restore original model");
        }
    };

    const currTemplate = _.find(canvas.slideTemplates, { id: canvas.model.template_id });
    const newTemplate = _.find(canvas.slideTemplates, { id: templateId });

    if (currTemplate.id === newTemplate.id && _.isEmpty(templateProps)) {
        restoreOriginalModel();
        return;
    }

    try {
        const template = new newTemplate();
        const renderNewTemplate = async sharedModel => {
            const templateDefaultData = _.merge({}, _.cloneDeep(template.defaultData));
            if (templateDefaultData.primary?.items?.length && detectListContent(sharedModel)?.length) {
                templateDefaultData.primary.items.splice(detectListContent(sharedModel).length);
            }

            canvas.model.elements = _.merge({}, templateDefaultData);
            canvas.model.template_id = templateId;
            canvas.updateTemplate(templateId);

            canvas.layouter.clear();
            await canvas.dryRefreshCanvas();
        };

        // check if the current template had a datasource linked and show confirmation dialog if new template doesn't support data links
        const currPrimaryElement = canvas.getPrimaryElement();
        if (currPrimaryElement.hasDataSourceLink() || currPrimaryElement.itemElements?.some(item => item.hasDataSourceLink())) {
            if (!["TableFrame", "Dashboard"].includes(template.elementType)) {
                const confirmed = await ShowConfirmationDialog({
                    title: "Are you sure?",
                    message: `${newTemplate.title} does not support data linking and your data will no longer update when the source file is changed.`,
                    okButtonLabel: "Continue"
                });

                if (!confirmed) {
                    restoreOriginalModel();
                    return;
                }
            }
        }

        // show spinner and hide canvas controls while doing the template switch
        PresentationEditorController.hideCanvasControls();
        canvas.showSpinner(null, true);

        // export the shared model from the current template and save it in the slide model
        const sharedModel = canvas.getCanvasElement().exportToSharedModel();
        canvas.slide.set("sharedModel", sharedModel);

        // render the new template with default data and show spinner
        await renderNewTemplate(sharedModel);
        canvas.showSpinner(null, true);

        // import the shared model into the new template that is already rendered
        const postProcessingFunction = canvas.getCanvasElement().importFromSharedModel({
            ...sharedModel, props: templateProps
        });

        // update the canvas model and call the callback function if provided
        if (postProcessingFunction) {
            await postProcessingFunction(canvas);
            await canvas.refreshCanvas({ transition: false });
        }

        await canvas.updateCanvasModel(false);
    } catch (err) {
        logger.error(err, "switchSlideTemplate() failed", { slideId: canvas.dataModel?.id, templateId });

        await restoreOriginalModel();

        if (err.message === "Layout doesn't fit") {
            ShowErrorDialog({
                title: "Oops, we can't fit your content into this slide!",
                message: "Beautiful.ai will to convert your existing content to the new slide but sometimes if there is just too much text or items on your slide, we won't be able to convert it. You can try reducing the number of items or amount of text before trying again."
            });
        } else {
            ShowErrorDialog({
                title: "Oops, something went wrong!",
                message: "Unfortunately we weren't able to convert your slide to this new smart slide template."
            });
        }
    }

    canvas.hideSpinner();
    PresentationEditorController.showCanvasControls();
}

/**
 * Utility method that handles switching between different chart types
 */
export async function switchChartType(canvas, chartType) {
    const newTemplateId = chartType === "areaspline" ? "chart_area_spline" : `chart_${chartType}`;
    await switchSlideTemplate(canvas, newTemplateId);
}

/**
 * Utility methods for detecting different types of data from the given shared model
 */

export function detectTextContent(model: SharedModel) {
    if (model.textContent?.length) {
        return model.textContent;
    }

    if (model.tasks?.length) {
        return model.tasks.map(({ text }) => text);
    }

    if (model.tabularData?.length) {
        const { data, categories, series } = model.tabularData[0];
        if (categories?.length && series?.length) {
            return categories.map((category, i) => ({
                mainText: { text: category },
                secondaryTexts: series.map(({ values }) => ({ text: values[i] }))
            }));
        } else if (data.length) {
            const textContent = [];
            for (let row = 0; row < data.length; row++) {
                for (let col = 0; col < data[row].length; col++) {
                    if (row === 0) {
                        textContent.push({ mainText: { text: data[row][col] }, secondaryTexts: [] });
                    } else {
                        textContent[col].secondaryTexts.push({ text: data[row][col] });
                    }
                }
            }
            return textContent;
        }
    }

    if (model.graphData?.length) {
        return model.graphData[0].nodes.map(({ textContent }) => textContent);
    }
}

export function detectAssets(model: SharedModel) {
    if (model.assets?.length) {
        return model.assets;
    }

    if (model.tasks?.length) {
        return model.tasks.map(({ asset }) => asset);
    }

    if (model.graphData?.length) {
        return model.graphData[0].nodes.map(({ asset }) => asset);
    }
}

export function detectListContent(model: SharedModel) {
    if (model.listContent?.length) {
        return model.listContent;
    }

    const textContent = detectTextContent(model) || [];
    const assets = detectAssets(model) || [];
    if (textContent.length || assets.length) {
        if (textContent.length > assets.length) {
            return textContent.map((text, i) => ({ text, ...(assets[i] ? { asset: assets[i] } : {}) }));
        } else {
            return assets.map((asset, i) => ({ ...(textContent[i] ? { text: textContent[i] } : {}), asset }));
        }
    }
}

export function detectCompareContent(model: SharedModel) {
    if (model.compareContent?.length) {
        return model.compareContent;
    }

    const tabularData = detectTabularData(model);
    if (tabularData?.data) {
        const { categories, series } = getValidChartDataFromCsv(tabularData.data, true);
        if (categories?.length && series?.length) {
            return categories.map((category, i) => ({
                value: series[0].data[i],
                text: { mainText: { text: category }, secondaryTexts: [] },
                asset: { value: series[0].data[i], name: series[0].name, type: AssetType.ICON },
                emphasized: false,
            }));
        }
    }

    const textContent = detectTextContent(model) || [];
    if (textContent.length) {
        return textContent.map((text, i) => ({
            text,
            value: Math.round(Math.random() * (80 - 40) + 40),
            asset: { value: i, name: text.mainText.text, type: AssetType.ICON },
            emphasized: false,
        }));
    }
}

export function detectTasks(model: SharedModel) {
    function addDefaultTaskData(task, i) {
        if (!task.category) {
            const categoryI = i % 3;
            const categoryText = categoryI === 0 ? "To Do" : categoryI === 1 ? "In Progress" : "Done";
            task.category = { mainText: { text: categoryText }, secondaryTexts: [] };
        }
        if (!task.startDate || !task.endDate) {
            const startDate = moment().add(i, "days").startOf("day").valueOf();
            const endDate = moment().add(i + 5, "days").endOf("day").valueOf();
            task.startDate = startDate;
            task.endDate = endDate;
        }
        return task;
    }

    if (model.tasks?.length) {
        return model.tasks.map(addDefaultTaskData);
    }

    const listContent = detectListContent(model);
    if (listContent?.length) {
        return listContent.map(({ text, asset }) => ({ text, asset })).map(addDefaultTaskData);
    }
}

export function detectTabularData(model: SharedModel) {
    if (model.tabularData?.length) {
        return model.tabularData[0];
    }

    if (model.compareContent?.length) {
        const categories = model.compareContent.map(({ text }) => text.mainText.text);
        const series = [{ name: "Values", values: model.compareContent.map(({ value }) => value) }];
        const data = [["", ...categories], ...series.map(s => ([s.name, ...s.values.map(v => v.toString())]))];
        return { data, categories, series };
    }

    const textContent = detectTextContent(model);
    if (textContent?.length) {
        const csvData = _.unzip(
            textContent
                .filter(t => t.mainText.text || t.secondaryTexts.length)
                .map(t => [t.mainText.text, ...t.secondaryTexts.map(({ text }) => text)])
                .map(row => [...row.map(cell => cell ?? ""), ...new Array(Math.max(...textContent.map(t => t.secondaryTexts.length + 1)) - row.length).fill("")])
        );
        return { data: csvData };
    }
}

export function detectGraphData(model: SharedModel) {
    if (model.graphData?.length) {
        return model.graphData[0];
    }

    const listContent = detectListContent(model);
    if (listContent?.length) {
        const nodes = []; const connectors = [];

        let x = 0; let y = 0; let isNewRow = false;
        for (let i = 0; i < listContent.length; i++) {
            const { text, asset } = listContent[i];

            const nodeType = asset && text
                ? NodeType.CONTENT_AND_TEXT : asset ? NodeType.CONTENT : NodeType.BOX;

            x += 0.25;
            if (x >= 0.8) {
                x = 0; y += 0.5;
            }
            nodes.push({
                id: `node-${i}`,
                type: nodeType,
                textContent: text,
                asset: asset,
                x, y,
            });

            if (i > 0) {
                connectors.push({
                    id: `connector-${i}`,
                    type: ConnectorType.STEP,
                    source: nodes[i - 1].id,
                    target: nodes[i].id,
                    props: {
                        startAnchor: "anchor-right",
                        endAnchor: isNewRow ? "anchor-top" : "anchor-free",
                        endDecoration: "arrow",
                    }
                });
            }
            isNewRow = x === 0;
        }

        return { nodes, connectors };
    }
}
