import { app } from "js/namespaces";
import PresentationLibraryController from "js/controllers/PresentationLibraryController";
import { ds } from "js/core/models/dataService";

export class Workspace {
    constructor(workspaceId) {
        this.id = workspaceId;
        this.icon = workspaceId == "personal" ? "person" : "business";
    }

    getName() {
        if (this.id === "personal") {
            return "Personal";
        } else if (ds.teams) {
            const workspaceDefaultTeam = ds.teams
                .find(team => team.get("isDefault") && team.get("orgId") === this.id);
            return workspaceDefaultTeam.get("name");
        } else {
            // public player
            return undefined;
        }
    }

    isPersonal() {
        return this.id === "personal";
    }

    isOrg() {
        return this.id !== "personal";
    }

    getDefaultTeam() {
        return ds.teams.find(team => team.get("isDefault") && team.get("orgId") === this.id);
    }

    getAnalytics() {
        return {
            "workspace_id": this.id,
            "workspace_type": this.id === "personal" ? "personal" : "organization",
            "organization_name": this.id === "personal" ? undefined : this.getName(),
            "current_plan": app.user.getAnalyticsPlan(this.id),
            // Cheap, but inaccurate as PresentationLibraryController may be not initialized in which case
            // it will return zero
            "current_slide_count": PresentationLibraryController.getSlideCount(this.id),
            "role": app.user.getRole(this.id),
        };
    }
}
